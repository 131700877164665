@font-face {
  font-family: 'feedback';
  src:  url('../fonts/feedback/icomoon.eot?3olljz');
  src:  url('../fonts/feedback/icomoon.eot?3olljz#iefix') format('embedded-opentype'),
  url('../fonts/feedback/icomoon.ttf?3olljz') format('truetype'),
  url('../fonts/feedback/icomoon.woff?3olljz') format('woff'),
  url('../fonts/feedback/icomoon.svg?3olljz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="feedback-"], [class*=" feedback-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'feedback' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.feedback-very_unsatisf:before {
  content: "\e900";
}
.feedback-very_satisf:before {
  content: "\e901";
}
.feedback-unsatisfied:before {
  content: "\e902";
}
.feedback-satisf:before {
  content: "\e903";
}
.feedback-neutral:before {
  content: "\e904";
}
